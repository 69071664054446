var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "md-layout" }, [
    _c(
      "div",
      { staticClass: "md-layout-item md-size-100" },
      [
        _vm._m(0),
        _c("div", { staticClass: "md-layout" }, [
          _c(
            "div",
            { staticClass: "md-layout-item md-xsmall-size-100" },
            [
              _c(
                "md-card",
                [
                  _c("md-card-content", [
                    _c("div", { staticClass: "text-center" }, [
                      _c("code", [_vm._v("md-xsmall-size-100")]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "md-layout-item md-xsmall-size-100" },
            [
              _c(
                "md-card",
                [
                  _c("md-card-content", [
                    _c("div", { staticClass: "text-center" }, [
                      _c("code", [_vm._v("md-xsmall-size-100")]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "md-layout-item md-xsmall-size-100" },
            [
              _c(
                "md-card",
                [
                  _c("md-card-content", [
                    _c("div", { staticClass: "text-center" }, [
                      _c("code", [_vm._v("md-xsmall-size-100")]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _vm._m(1),
        _c("div", { staticClass: "md-layout" }, [
          _c(
            "div",
            { staticClass: "md-layout-item md-small-size-100" },
            [
              _c(
                "md-card",
                [
                  _c("md-card-content", [
                    _c("div", { staticClass: "text-center" }, [
                      _c("code", [_vm._v("md-small-size-100")]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "md-layout-item md-small-size-100" },
            [
              _c(
                "md-card",
                [
                  _c("md-card-content", [
                    _c("div", { staticClass: "text-center" }, [
                      _c("code", [_vm._v("md-small-size-100")]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "md-layout-item md-small-size-100" },
            [
              _c(
                "md-card",
                [
                  _c("md-card-content", [
                    _c("div", { staticClass: "text-center" }, [
                      _c("code", [_vm._v("md-small-size-100")]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _vm._m(2),
        _c("div", { staticClass: "md-layout" }, [
          _c(
            "div",
            { staticClass: "md-layout-item md-medium-size-100" },
            [
              _c(
                "md-card",
                [
                  _c("md-card-content", [
                    _c("div", { staticClass: "text-center" }, [
                      _c("code", [_vm._v("md-medium-size-100")]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "md-layout-item md-medium-size-100" },
            [
              _c(
                "md-card",
                [
                  _c("md-card-content", [
                    _c("div", { staticClass: "text-center" }, [
                      _c("code", [_vm._v("md-medium-size-100")]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "md-layout-item md-medium-size-100" },
            [
              _c(
                "md-card",
                [
                  _c("md-card-content", [
                    _c("div", { staticClass: "text-center" }, [
                      _c("code", [_vm._v("md-medium-size-100")]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _vm._m(3),
        _c("div", { staticClass: "md-layout" }, [
          _c(
            "div",
            { staticClass: "md-layout-item md-large-size-100" },
            [
              _c(
                "md-card",
                [
                  _c("md-card-content", [
                    _c("div", { staticClass: "text-center" }, [
                      _c("code", [_vm._v("md-large-size-100")]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "md-layout-item md-large-size-100" },
            [
              _c(
                "md-card",
                [
                  _c("md-card-content", [
                    _c("div", { staticClass: "text-center" }, [
                      _c("code", [_vm._v("md-large-size-100")]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "md-layout-item md-large-size-100" },
            [
              _c(
                "md-card",
                [
                  _c("md-card-content", [
                    _c("div", { staticClass: "text-center" }, [
                      _c("code", [_vm._v("md-large-size-100")]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _vm._m(4),
        _c("div", { staticClass: "md-layout" }, [
          _c(
            "div",
            { staticClass: "md-layout-item md-xlarge-size-100" },
            [
              _c(
                "md-card",
                [
                  _c("md-card-content", [
                    _c("div", { staticClass: "text-center" }, [
                      _c("code", [_vm._v("md-large-size-100")]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "md-layout-item md-xlarge-size-100" },
            [
              _c(
                "md-card",
                [
                  _c("md-card-content", [
                    _c("div", { staticClass: "text-center" }, [
                      _c("code", [_vm._v("md-xlarge-size-100")]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "md-layout-item md-xlarge-size-100" },
            [
              _c(
                "md-card",
                [
                  _c("md-card-content", [
                    _c("div", { staticClass: "text-center" }, [
                      _c("code", [_vm._v("md-xlarge-size-100")]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _vm._m(5),
        _c("div", { staticClass: "md-layout" }, [
          _c(
            "div",
            {
              staticClass: "md-layout-item md-medium-size-33 md-small-size-50",
            },
            [
              _c(
                "md-card",
                [
                  _c("md-card-content", [
                    _c("div", { staticClass: "text-center" }, [
                      _c("code", [
                        _vm._v("md-medium-size-33 md-small-size-50"),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "md-layout-item md-medium-size-33 md-small-size-50",
            },
            [
              _c(
                "md-card",
                [
                  _c("md-card-content", [
                    _c("div", { staticClass: "text-center" }, [
                      _c("code", [
                        _vm._v("md-medium-size-33 md-small-size-50"),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "md-layout-item md-medium-size-33 md-small-size-50",
            },
            [
              _c(
                "md-card",
                [
                  _c("md-card-content", [
                    _c("div", { staticClass: "text-center" }, [
                      _c("code", [
                        _vm._v("md-medium-size-33 md-small-size-50"),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _vm._m(6),
        _c("div", { staticClass: "md-layout" }, [
          _c(
            "div",
            { staticClass: "md-layout-item md-size-25 md-small-size-50" },
            [
              _c(
                "md-card",
                [
                  _c("md-card-content", [
                    _c("div", { staticClass: "text-center" }, [
                      _c("code", [_vm._v("md-size-25")]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "md-layout-item md-size-25 ml-auto md-small-size-50",
            },
            [
              _c(
                "md-card",
                [
                  _c("md-card-content", [
                    _c("div", { staticClass: "text-center" }, [
                      _c("code", [_vm._v("md-size-25 ml-auto")]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "md-layout" }, [
          _c(
            "div",
            {
              staticClass: "md-layout-item md-size-25 mx-auto md-small-size-50",
            },
            [
              _c(
                "md-card",
                [
                  _c("md-card-content", [
                    _c("div", { staticClass: "text-center" }, [
                      _c("code", [_vm._v("md-size-25 mx-auto")]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "md-layout-item md-size-25 mx-auto md-small-size-50",
            },
            [
              _c(
                "md-card",
                [
                  _c("md-card-content", [
                    _c("div", { staticClass: "text-center" }, [
                      _c("code", [_vm._v("md-size-25 mx-auto")]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "md-layout" }, [
          _c(
            "div",
            {
              staticClass: "md-layout-item md-size-50 mx-auto md-small-size-50",
            },
            [
              _c(
                "md-card",
                [
                  _c("md-card-content", [
                    _c("div", { staticClass: "text-center" }, [
                      _c("code", [_vm._v("md-size-50 mx-auto")]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _c("h3", { staticClass: "title text-center" }, [_vm._v("Paragraphs")]),
        _c(
          "md-card",
          [
            _c("md-card-content", [
              _c("div", { staticClass: "md-layout" }, [
                _c(
                  "div",
                  {
                    staticClass: "md-layout-item md-size-50 md-small-size-100",
                  },
                  [
                    _c("h3", [_vm._v("Some card-title Here")]),
                    _c("p", [
                      _vm._v(
                        'One morning, when Gregor Samsa woke from troubled dreams, he found himself transformed in his bed into a horrible vermin. He lay on his armour-like back, and if he lifted his head a little he could see his brown belly, slightly domed and divided by arches into stiff sections. The bedding was hardly able to cover it and seemed ready to slide off any moment. His many legs, pitifully thin compared with the size of the rest of him, waved about helplessly as he looked. "What\'s happened to me?" he thought.'
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "md-layout-item md-size-50 md-small-size-100",
                  },
                  [
                    _c("h3", [_vm._v("Another card-title Here")]),
                    _c("p", [
                      _vm._v(
                        'One morning, when Gregor Samsa woke from troubled dreams, he found himself transformed in his bed into a horrible vermin. He lay on his armour-like back, and if he lifted his head a little he could see his brown belly, slightly domed and divided by arches into stiff sections. The bedding was hardly able to cover it and seemed ready to slide off any moment. His many legs, pitifully thin compared with the size of the rest of him, waved about helplessly as he looked. "What\'s happened to me?" he thought.'
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "md-layout-item md-size-33 md-small-size-100",
                  },
                  [
                    _c("h3", [_vm._v("Some card-title Here")]),
                    _c("p", [
                      _vm._v(
                        "One morning, when Gregor Samsa woke from troubled dreams, he found himself transformed in his bed into a horrible vermin. He lay on his armour-like back, and if he lifted his head a little he could see his brown belly, slightly domed and divided by arches into stiff sections. The bedding was hardly able to cover it and seemed ready to slide off any moment."
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "md-layout-item md-size-33 md-small-size-100",
                  },
                  [
                    _c("h3", [_vm._v("Another card-title Here")]),
                    _c("p", [
                      _vm._v(
                        "One morning, when Gregor Samsa woke from troubled dreams, he found himself transformed in his bed into a horrible vermin. He lay on his armour-like back, and if he lifted his head a little he could see his brown belly, slightly domed and divided by arches into stiff sections. The bedding was hardly able to cover it and seemed ready to slide off any moment."
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "md-layout-item md-size-33 md-small-size-100",
                  },
                  [
                    _c("h3", [_vm._v("Another card-title Here")]),
                    _c("p", [
                      _vm._v(
                        "One morning, when Gregor Samsa woke from troubled dreams, he found himself transformed in his bed into a horrible vermin. He lay on his armour-like back, and if he lifted his head a little he could see his brown belly, slightly domed and divided by arches into stiff sections. The bedding was hardly able to cover it and seemed ready to slide off any moment."
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "md-layout-item md-size-33 md-small-size-100",
                  },
                  [
                    _c("h3", [_vm._v("Some card-title Here")]),
                    _c("p", [
                      _vm._v(
                        "One morning, when Gregor Samsa woke from troubled dreams, he found himself transformed in his bed into a horrible vermin. He lay on his armour-like back, and if he lifted his head a little he could see his brown belly, slightly domed and divided by arches into stiff sections. The bedding was hardly able to cover it and seemed ready to slide off any moment."
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "md-layout-item md-size-66 md-small-size-100",
                  },
                  [
                    _c("h3", [_vm._v("Another card-title Here")]),
                    _c("p", [
                      _vm._v(
                        "One morning, when Gregor Samsa woke from troubled dreams, he found himself transformed in his bed into a horrible vermin. He lay on his armour-like back, and if he lifted his head a little he could see his brown belly, slightly domed and divided by arches into stiff sections. The bedding was hardly able to cover it and seemed ready to slide off any moment. One morning, when Gregor Samsa woke from troubled dreams, he found himself transformed in his bed into a horrible vermin. He lay on his armour-like back, and if he lifted his head a little he could see his brown belly, slightly domed and divided by arches into stiff sections. The bedding was hardly able to cover it and seemed ready to slide off any moment."
                      ),
                    ]),
                  ]
                ),
              ]),
            ]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h4", { staticClass: "title" }, [
      _vm._v("XS Grid "),
      _c("small", [_vm._v("Always Horizontal")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h4", { staticClass: "title" }, [
      _vm._v("SM Grid "),
      _c("small", [_vm._v("Collapsed at 960px")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h4", { staticClass: "title" }, [
      _vm._v("MD Grid "),
      _c("small", [_vm._v("Collapsed at 1280px")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h4", { staticClass: "title" }, [
      _vm._v("LG Grid "),
      _c("small", [_vm._v("Collapsed at 1903px")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h4", { staticClass: "title" }, [
      _vm._v("XL Grid "),
      _c("small", [_vm._v("larger than 1904px")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h4", { staticClass: "title pl-3" }, [
      _vm._v("Mixed Grid "),
      _c("small", [_vm._v("Showing different sizes on different screens")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h4", { staticClass: "title" }, [
      _vm._v("Offset Grid "),
      _c("small", [_vm._v("Adding some space when needed")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }